import React, { useState, useEffect } from "react";
import { Section, TitleComponent, ContactComponent } from "../../components";
import "./styles.scss";
import { translate } from "../../translations/localization";
import { connect, useDispatch, useSelector } from "react-redux";
import { getArticles, sendEmail } from "../../actions";
import { toast } from "react-toastify";
import partner1 from "../../assets/images/Adella-Hotel-Logo.png";
import partner2 from "../../assets/images/Adella-Clinic-Logo.png";
import partner3 from "../../assets/images/Fleets_logo.png";
import "react-toastify/dist/ReactToastify.css";

const partners = [
  { image: partner1, url: "https://adellaboutiquehotel.com/bg/" },
  { image: partner2, url: "https://adellaclinic.com/" },
  { image: partner3, url: "https://fleets.one/" },
];

const About = () => {
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Please wait a few seconds.");
  const [success, setSuccess] = useState(false);
  const [selected, setSelected] = useState(0);
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;

  const [article] = useSelector(({ articles }) => articles?.docs) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        let newLoadingMessage = loadingMessage + ".";
        if (newLoadingMessage === "Please wait a few seconds.....") {
          newLoadingMessage = "Please wait a few seconds.";
        }
        setLoadingMessage(newLoadingMessage);
      }, 500);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [loading, loadingMessage]);

  function validateEmail() {
    const regex = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (regex.test(email) === false) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    dispatch(getArticles({}));
  }, [dispatch]);

  return (
    <div className="property-component-container">
      <Section
        title={translate("about1-1")}
        right={isMobileVersion === 0 && true}
        fullwidth
        image={`url(${require("../../assets/images/About-us-section-1.png")})`}
        height={isMobileVersion === 0 && 100}
        invert={isMobileVersion === 1 ? false : true}
        backgroundWhite={isMobileVersion === 1 ? true : false}
        titleMobile={isMobileVersion === 1 && true}
        renderContent={() => {
          return (
            <>
              <p style={{ width: "90%" }}>
                {translate("about1-2")} <br />
                <br /> {translate("about1-3")}
              </p>
            </>
          );
        }}
      />

      {isMobileVersion === 0 ? (
        selected === 0 ? (
          <Section
            fullwidth
            title={translate("clients7-1")}
            map={selected === 0 ? 1 : 0}
            X={42.670077300179145}
            Y={23.31418025044331}
            image={""}
            fullInner
            renderContent={() => {
              return (
                <>
                  <h2 style={{ fontSize: "22px" }}>{translate("clients7-2")}</h2>
                  <div className="upper-arrow-container">
                    <div className={`inner-arrow ${selected === 0 ? "sel" : ""}`} />
                    <div className={`inner-arrow--1 ${selected === 1 ? "sel" : ""}`} />
                  </div>
                  <div className="inner-contact-tabs-container">
                    <div className="first-element" onClick={() => setSelected(0)}>
                      <div className={`inner-icon ${selected === 0 ? "selected" : ""}`} />
                    </div>
                    <div className="second-element" onClick={() => setSelected(1)}>
                      <div className={`inner-icon-1 ${selected === 1 ? "sell" : ""}`} id={"second"} />
                    </div>
                  </div>
                  <div className="info-container" style={{ marginTop: "7%" }}>
                    <div className="inner-info-container">
                      <p>
                        <b>{translate("clients7-3")}</b>
                      </p>
                      <p style={{ marginTop: "-3%", fontSize: "16px" }}>office@winkcharging.com</p>
                    </div>
                    <div className="inner-info-container">
                      <p>
                        <b>{translate("clients7-4")}</b>
                      </p>
                      <p style={{ marginTop: "-3%", fontSize: "16px" }}>+359 887 141 117</p>
                    </div>
                  </div>
                  <div className="info-container" style={{ marginTop: "7%" }}>
                    <div className="inner-info-container">
                      <p style={{ fontSize: "15px" }}>
                        <b>{translate("clients7-5")}</b>
                      </p>
                      <p style={{ marginTop: "-2%" }}>{translate("address1")}</p>
                    </div>

                    <div className="inner-info-container">
                      <p style={{ fontSize: "15px" }}>
                        <b>{translate("clients7-6")}</b>
                      </p>
                      <div className="flex">
                        <div className="icon facebook" onClick={() => window.open("https://www.facebook.com/profile.php?id=100082523756187")} />
                        <div className="icon instagram" onClick={() => window.open("https://www.instagram.com/wink.charging?igsh=bTVkbXZtZzkxMXBz")} />
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          />
        ) : (
          <div className={`contact-component-container`}>
            <div className="left-container" style={{ width: "50%" }} />
            <div className="right-container" style={{ width: "50%" }}>
              <div className="inner-container" style={{ height: "85%", width: "90%" }}>
                <TitleComponent title={translate("clients7-1")} />
                <h2 style={{ textAlign: "left", fontSize: "22px" }}>{translate("clients7-2")}</h2>
                <div className="upper-arrow-container">
                  <div className={`inner-arrow-1 ${selected === 1 ? "sel" : ""}`} />
                </div>
                <div className="inner-contact-tabs-container">
                  <div className="first-element" onClick={() => setSelected(0)}>
                    <div className={`inner-icon ${selected === 0 ? "selected" : ""}`} />
                  </div>
                  <div
                    className="second-element"
                    onClick={() => {
                      setSelected(1);
                    }}
                  >
                    <div className={`inner-icon-1 ${selected === 1 ? "sell" : ""}`} id="second" />
                  </div>
                </div>
                <div className="flex-container">
                  <div className="form-input-wrapper">
                    <input required className="form-input" type="text" value={fullName} placeholder={translate("contactForm1")} onChange={(event) => setFullName(event.target.value)} />
                  </div>
                  <div className="form-input-wrapper">
                    <input required className="form-input" type="text" value={company} placeholder={translate("contactForm2")} onChange={(event) => setCompany(event.target.value)} />
                  </div>
                </div>
                <div className="flex-container">
                  <div className="form-input-wrapper">
                    <input required className="form-input" type="text" value={email} placeholder={translate("contactForm3")} onChange={(event) => setEmail(event.target.value)} />
                  </div>
                  <div className="form-input-wrapper">
                    <input required className="form-input" type="text" value={phone} placeholder={translate("contactForm4")} onChange={(event) => setPhone(event.target.value)} />
                  </div>
                </div>
                <div className="flex-end">
                  <div className="flex-container">
                    <div className="form-textarea-wrapper">
                      <textarea required value={message} placeholder={translate("contactForm5")} style={{ width: "40vw" }} onChange={(event) => setMessage(event.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="checkboxes"></div>
                <div className="button-right">
                  <div
                    className="button-container"
                    onClick={() => {
                      if (!loading && validateEmail()) {
                        document.getElementById("error").style.display = "none";
                        setLoading(true);
                        setSuccess(false);
                        const payload = {
                          system: "wink",
                          sender: fullName,
                          company: company,
                          email: email,
                          phoneNumber: phone,
                          message: message,
                          onSuccess: () => {
                            setLoading(false);
                            setSuccess(true);
                            setFullName("");
                            setCompany("");
                            setEmail("");
                            setPhone("");
                            setMessage("");
                          },
                          onError: (error) => {
                            setLoading(false);
                            toast(error);
                          },
                        };
                        sendEmail(payload);
                      } else document.getElementById("error").style.display = "block";
                    }}
                  >
                    {translate("send")}
                  </div>
                </div>
                <p style={{ display: "none" }} id="error">
                  {translate("contactForm8")}
                </p>
                {loading && <p>{loadingMessage}</p>}
                {success && <p>{translate("contactForm9")}</p>}
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          <TitleComponent title={translate("clients7-1")} fullLine mobile />
          <h6 style={{ width: "90%", fontSize: "17px", marginLeft: "5%", height: "3%", marginTop: "2.5%", lineHeight: "1.5em" }}>{translate("clients7-2")}</h6>
          <div className="mobile-tab">
            <div className="mobile-icon" />
          </div>
          <Section
            map={1}
            mobile={isMobileVersion}
            X={42.670077300179145}
            Y={23.31418025044331}
            height={70}
            title={""}
            renderContent={() => {
              return (
                <>
                  <div className="double-info" style={{ marginTop: "10%" }}>
                    <p className="info-title">
                      <b>{translate("clients7-3")}:</b>
                    </p>
                    <p className="second-field">office@winkcharging.com</p>
                  </div>
                  <div className="double-info">
                    <p className="info-title">
                      <b>{translate("clients7-4")}:</b>
                    </p>
                    <p className="second-field">+359 887 141 117</p>
                  </div>
                  <div className="double-info">
                    <p className="info-title">
                      <b>{translate("clients7-5")}:</b>
                    </p>
                    <p className="second-field">{translate("address1")}</p>
                  </div>
                  <div className="double-info">
                    <p className="info-title">
                      <b>{translate("clients7-6")}</b>
                    </p>
                    <div className="flex">
                      <div className="icon facebook" onClick={() => window.open("https://www.facebook.com/profile.php?id=100082523756187")} />
                      <div className="icon instagram" onClick={() => window.open("https://www.instagram.com/wink.charging?igsh=bTVkbXZtZzkxMXBz")} />
                    </div>
                  </div>
                </>
              );
            }}
          />
          <div className="mobile-tab">
            <div className="mobile-icon contact" />
          </div>
          <ContactComponent mobile={1} />
        </>
      )}

      <div className="partners-container">
        <TitleComponent title={translate("about-us-partners")} custom="partners-header" />
        <div className="partners-flex-container">
          {partners.map(({ image, url }) => (
            <div className="partner-container" onClick={() => window.open(url)}>
              <div className="partner-logo" style={{ backgroundImage: `url(${image})` }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  sendEmail: (payload) => dispatch(sendEmail(payload)),
});
export default connect(null, mapDispatchToProps)(About);
